import { useEffect } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Block1 from './components/block1';
import Block2 from './components/block2';
import Block3 from './components/block3';
import Block4 from './components/block4';
import BlockFaq from './components/blockfaq';
import BlockMail from './components/blockmail';
import { init } from './comon';

function App() {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Header />

      <Block1 />
      <Block2 />
      <Block3 />
      <Block4 />
      <BlockFaq />
      <BlockMail />

      <Footer />
    </>
  );
}

export default App;
