import AppLinks from "./applinks";
import Logo from "./logo";

function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="footer__logo">
          <Logo />
        </div>
        <AppLinks />
        <div className="copyright">Все права защищены &copy; BSTR 2021</div>
      </div>
    </div>
  );
}

export default Footer;
