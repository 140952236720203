import { useState } from 'react';
import config from '../../config';

type IFormState = Record<string, string>;

export default function MailForm() {
  const [data, setData] = useState<IFormState>({ name: '', email: '', message: '' });
  const [error, setError] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');

  const hideFormMsg = () => {
    setMsg('');
  };

  const submit = (event: any) => {
    event.preventDefault();

    //fetch(form.action, {method:'post', body: new FormData(form)});
    window.jQuery
      .ajax({
        url: config.mailToMethod,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
      })
      .done(function () {
        setError(false);
        setMsg('Ваше сообщение отправлено');
        setData({ name: '', message: '', email: '' });
        setTimeout(hideFormMsg, 2000);
      })
      .fail(function (data: any) {
        //console.log('🚀 ~ error', data);
        setError(true);
        setMsg(
          data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Ошибка отправки сообщения'
        );
        setTimeout(hideFormMsg, 2000);
      });
    return false;
  };

  const changeInput = (ev: any) => {
    const out: IFormState = { ...data };
    const name = ev.target.name;
    const val = ev.target.value;
    out[name] = val;
    setData(out);
  };

  return (
    <form className="form" method="post" onSubmit={submit}>
      <input type="text" name="name" value={data.name} onChange={changeInput} placeholder="Имя" required />

      <input type="email" name="email" value={data.email} onChange={changeInput} placeholder="Email" required />

      <textarea name="message" placeholder="Сообщение" onChange={changeInput} value={data.message} />

      <div className="form__buttons">
        <button className="btn" type="submit">
          Отправить
        </button>
      </div>

      {msg && <div className={'form__messages' + (error ? ' error' : '')}>{msg}</div>}
    </form>
  );
}
