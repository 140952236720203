import MailForm from './forms/mail-form';

function BlockMail() {
  return (
    <div className="blockmail">
      <div className="container">
        <div className="blockmail__flex">
          <div className="blockmail__left">
            <img src="/images/blockmail/img1.png" alt="" />
          </div>
          <div className="blockmail__right">
            <div className="title">Идеи, предложения?</div>
            <div className="subtitle">Напишите и мы обязательно ответим!</div>
            <MailForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockMail;
