import config from "../config";

function Header() {
  return (
    <div className="mobile-links">
      <a href={config.links.ios} target="_blank" rel="noreferrer">
        <img src="/images/block1/appstore.svg" alt="appstore" />
      </a>
      <a href={config.links.android} rel="noreferrer">
        <img src="/images/block1/googleplay.svg" alt="googleplay" />
      </a>
    </div>
  );
}

export default Header;
