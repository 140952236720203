function Block2() {
  return (
    <div className="block block2" id="avd">
      <div className="container">
        <div className="two-columns">
          <div className="block2__left two-columns__left">
            <div className="title">
              Оплачивайте <br />
              городские парковки, <br />
              <b>не выходя из машины</b>
            </div>
            <div className="text">
              Больше не нужно искать свободные места, паркоматы, запоминать время парковки - с помощью BSTR оплата и
              продление парковки происходит не выходя из машины. Приложение запомнит предыдущие сессии, напомнит об
              окончании парковки и сделает поездку в центр города комфортнее.
            </div>
          </div>
          <div className="block2__right two-columns__right">
            <ul className="list1">
              <li>
                <img src="/images/block2/icon1.svg" alt="" />
                <span>Без регистрации</span>
              </li>
              <li>
                <img src="/images/block2/icon2.svg" alt="" />
                <span>Напоминания об окончании парковки</span>
              </li>
              <li>
                <img src="/images/block2/icon3.svg" alt="" />
                <span>История оплат</span>
              </li>
              <li>
                <img src="/images/block2/icon4.svg" alt="" />
                <span>Добавление нескольких машин</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Block2;
