import AppLinks from "./applinks";

function Block1() {
  return (
    <div className="block block1 two-columns" id="about">
      <div className="container">
        <div className="two-columns">
          <div className="block1__left two-columns__left">
            <h1>
              Транспортное приложение <b>Петербурга</b>
            </h1>
            <div className="title-gray">Мобильная оплата в пару кликов.</div>
            <ul className="list2">
              <li>
                <img width={28} src="/images/block1/icon_parkingmini.svg" alt="" />
                <span>Городские парковки</span>
              </li>
              <li>
                <img width={24} src="/images/block1/icon_washmini.svg" alt="" />
                <span>Мойки</span>
              </li>
              <li>
                <img width={30} src="/images/block1/icon_cardmini.svg" alt="" />
                <span>“Подорожник”</span>
              </li>
              <li>
                <img width={30} src="/images/block1/icon_ticketmini.svg" alt="" />
                <span>Билет на транспорт</span>
              </li>
            </ul>
            <AppLinks />
          </div>
          <div className="block1__right two-columns__right">
            <img src="/images/block1/img1.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Block1;
