function Block3() {
  return (
    <div className="block block3" id="hiw">
      <div className="title">Как это работает?</div>
      <div className="block3__back">
        <div className="container">
          <div className="block3__items">
            <div className="block3__item">
              <div className="block3__item-img">
                <img src="/images/block3/img1.svg" alt="" />
              </div>
              <div className="block3__item-title">Выберите место для парковки</div>
            </div>
            <div className="block3__item">
              <div className="block3__item-img">
                <img src="/images/block3/img2.svg" alt="" />
              </div>
              <div className="block3__item-title">Укажите время</div>
            </div>
            <div className="block3__item">
              <div className="block3__item-img">
                <img src="/images/block3/img3.svg" alt="" />
              </div>
              <div className="block3__item-title">Оплатите парковку банковской картой</div>
            </div>
            <div className="block3__item">
              <div className="block3__item-img">
                <img src="/images/block3/img4.svg" alt="" />
              </div>
              <div className="block3__item-title">Добавьте время, если необходимо</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Block3;
