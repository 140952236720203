import Menu from './menu';

function Header() {
  return (
    <div className="header">
      <div className="container">
        <div className="header__flex">
          <div className="header__left">
            <a className="header__logo" href="/">
              <img src="images/logo_bstr.svg" alt="BSTR" height="59" />
            </a>
          </div>
          <div className="header__right">
            <Menu />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
