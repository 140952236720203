import AppLinks from './applinks';

function Block4() {
  return (
    <div className="block block4">
      <div className="container">
        <div className="title">
          <b>Другие</b> наши услуги
        </div>
        <div className="block4__items">
          <div className="row">
            <div className="block4__item block4__item--1">
              <div className="block4__item-title">Пополнение подорожника</div>
            </div>
            <div className="block4__item block4__item--2">
              <div className="block4__item-title">Оплата моек самообслуживания</div>
            </div>
          </div>
          <div className="row">
            <div className="block4__item block4__item--3">
              <div className="block4__item-title">Покупка разового билета на транспорт</div>
            </div>
            <div className="block4__item--mobile" id="apps">
              <div className="title-small">
                Оплачивайте транспорт с <br />
                <b>BSTR</b> в пару кликов
              </div>
              <AppLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Block4;
