import config from '../config';
import BlockFaqItem, { IFaqItem } from './blockfaq-item';

function BlockFaq() {
  const items = config.faq.map((el: IFaqItem, idx: any) => {
    return <BlockFaqItem {...el} key={idx} />;
  });

  return (
    <div className="block blockfaq" id="faq">
      <div className="container">
        <div className="title">Частые вопросы</div>
        {items}
      </div>
    </div>
  );
}

export default BlockFaq;
